import React from 'react';
import { formatFileSize, getFileTypeFromMime } from '../service/Files';

function ListItem(props) {
    const { file, remove } = props;

    return <tr>
        <td>{file.name}</td>
        <td>{getFileTypeFromMime(file.type).toUpperCase()}</td>
        <td>{formatFileSize(file.size)}</td>
        <td><button onClick={(e) => remove(e, file.name)}>Remove</button></td>
    </tr>;
}

export default ListItem;