import React, { Component } from "react";
import ListViewEmpty from './ListViewEmpty';
import ListItem from './ListItem';
import { Settings } from '../service/Settings';
import { getTotalFileSize, formatFileSize, sortFiles } from '../service/Files';
import sortIcon from '../img/sort-up-solid.svg';
import '../css/ListView.css';

class ListView extends Component {
    componentDidMount() {
        window.gtag('config', 'G-FB09WY2WQP', {
            'page_title': 'UploadList',
            'page_path': '/uploadList'
        });
    }

    render() {
        const { remove, removeAll, dragOver, sortCol, sortDir, setSort, addFiles } = this.props;
        const files = sortFiles(this.props.files, sortCol, sortDir);
        const sortByName = sortCol === 'name' ? 'Sorted' + sortDir : '';
        const sortByType = sortCol === 'type' ? 'Sorted' + sortDir : '';
        const sortBySize = sortCol === 'size' ? 'Sorted' + sortDir : '';

        let info = files.length + '/' + Settings.filesPerUpload + ' Files';
        if (files.length > 0) {
            info += ' - ' + formatFileSize(getTotalFileSize(files)) +
                '/' + formatFileSize(Settings.bytesPerUpload);
        }

        if (files.length === 0) {
            return <ListViewEmpty dragOver={dragOver} addFiles={addFiles} />;
        }

        return <div className="ListView" onDragOver={(e) => dragOver(e)}>
            <div className="ListViewInfoArea">
                <div className="ListViewInfo">{info}</div>
                <div>
                    <input type="button" value="Remove All" disabled={files.length === 0} onClick={removeAll} />
                </div>
            </div>
            <table className="ListViewTable" cellSpacing="0">
                <thead>
                <tr>
                    <th className={sortByName} onMouseDown={() => setSort('name')}>Files {sortCol === 'name' ? <img className={sortByName} src={sortIcon} alt="sort" /> : null}</th>
                    <th className={sortByType} onMouseDown={() => setSort('type')}>Type {sortCol === 'type' ? <img className={sortByType} src={sortIcon} alt="sort" /> : null}</th>
                    <th className={sortBySize} onMouseDown={() => setSort('size')}>Size {sortCol === 'size' ? <img className={sortBySize} src={sortIcon} alt="sort" /> : null}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {files.map((file) =>
                    <ListItem key={file.name}
                              file={file}
                              remove={(e, name) => remove(e, name)}
                    />
                )}
                </tbody>
            </table>
        </div>;
    }
}

export default ListView;