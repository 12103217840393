class Cookie {
    static set(key, value) {
        document.cookie = [
            key + '=' + value + ';',
            'path=/;'
        ].join(' ');
    }

    static clear(key) {
        document.cookie = [
            key + '=;',
            'expires=Thu, 01 Jan 1970 00:00:00 UTC;',
            'path=/;'
        ].join(' ');
    }

    static get(key) {
        let cookies = decodeURIComponent(document.cookie).split(';');
        let cookie, i;

        for (i = 0; i < cookies.length; i ++) {
            cookie = cookies[i].split('=');

            if (cookie[0].trim() === key) {
                return cookie[1].trim();
            }
        }

        return null;
    }
}

export default Cookie;