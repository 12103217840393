class Ajax {
    static async get(url, progressCb) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.open('get', url, true);

            xhr.setRequestHeader(
                'X-Frame-Options',
                'SAMEORIGIN'
            );
            xhr.setRequestHeader(
                'X-Content-Type-Options',
                'nosniff'
            );
            xhr.setRequestHeader(
                'Strict-Transport-Security',
                'max-age=7776000; includeSubDomains; preload'
            );
            xhr.setRequestHeader(
                'Content-Security-Policy',
                'default-src https:'
            );
            xhr.setRequestHeader(
                'X-Permitted-Cross-Domain-Policies',
                'none'
            );

            xhr.onload = () => {
                if (   xhr.readyState !== 4
                    || (xhr.status !== 200
                    && xhr.status !== 201)
                ) {
                    reject({state:xhr.readyState, status:xhr.status});
                    return;
                }

                try {
                    resolve(
                        xhr.responseText === ''
                            ? null
                            : JSON.parse(xhr.responseText)
                    );
                }
                catch (e) {
                    reject({state:xhr.readyState, status:xhr.status});
                }
            };

            xhr.onerror = () => {
                reject({state:xhr.readyState, status:xhr.status});
            };

            if (typeof progressCb === 'function') {
                xhr.upload.onprogress = progressCb;
            }

            xhr.send();
        });
    }

    static async post(url, contentType, data, progressCb) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.open('post', url, true);

            if (typeof contentType === 'string' && contentType !== '') {
                xhr.setRequestHeader(
                    'Content-Type',
                    contentType
                );
            }

            xhr.setRequestHeader(
                'X-Frame-Options',
                'SAMEORIGIN'
            );
            xhr.setRequestHeader(
                'X-Content-Type-Options',
                'nosniff'
            );
            xhr.setRequestHeader(
                'Strict-Transport-Security',
                'max-age=7776000; includeSubDomains; preload'
            );
            xhr.setRequestHeader(
                'Content-Security-Policy',
                'default-src https:'
            );
            xhr.setRequestHeader(
                'X-Permitted-Cross-Domain-Policies',
                'none'
            );

            xhr.onload = () => {
                if (   xhr.readyState !== 4
                    || (xhr.status !== 200
                    && xhr.status !== 201)
                ) {
                    reject({state:xhr.readyState, status:xhr.status});
                    return;
                }

                try {
                    resolve(
                        xhr.responseText === ''
                            ? null
                            : JSON.parse(xhr.responseText)
                    );
                }
                catch (e) {
                    reject({state:xhr.readyState, status:xhr.status});
                }
            };

            xhr.onerror = () => {
                reject({state:xhr.readyState, status:xhr.status});
            };

            if (typeof progressCb === 'function') {
                xhr.upload.onprogress = progressCb;
            }

            xhr.send(data);
        });
    }

    // static async send(formData, url, progressCb, contentType) {
    //     return new Promise((resolve, reject) => {
    //         const xhr = new XMLHttpRequest();
    //
    //         xhr.open('post', url, true);
    //
    //         if (   typeof contentType === 'string'
    //             && contentType !== ''
    //         ) {
    //             xhr.setRequestHeader(
    //                 'Content-Type',
    //                 contentType
    //                 // 'multipart/form-data'
    //                 // 'application/x-www-form-urlencoded'
    //             );
    //         }
    //
    //         xhr.setRequestHeader(
    //             'X-Frame-Options',
    //             'SAMEORIGIN'
    //         );
    //         xhr.setRequestHeader(
    //             'X-Content-Type-Options',
    //             'nosniff'
    //         );
    //         xhr.setRequestHeader(
    //             'Strict-Transport-Security',
    //             'max-age=7776000; includeSubDomains; preload'
    //         );
    //         xhr.setRequestHeader(
    //             'Content-Security-Policy',
    //             'default-src https:'
    //         );
    //         xhr.setRequestHeader(
    //             'X-Permitted-Cross-Domain-Policies',
    //             'none'
    //         );
    //
    //         xhr.onload = () => {
    //             if (xhr.readyState === 4) {
    //                 if (   xhr.status === 200
    //                     || xhr.status === 201
    //                 ) {
    //                     try {
    //                         resolve(JSON.parse(xhr.responseText));
    //                     }
    //                     catch (e) {
    //                         reject('invalid response');
    //                     }
    //                     return;
    //                 }
    //             }
    //             reject('request failed (' + xhr.readyState + '/' + xhr.status + ')');
    //         };
    //
    //         xhr.onerror = () => {
    //             reject(xhr.statusText);
    //         };
    //
    //         if (typeof progressCb === 'function') {
    //             xhr.upload.onprogress = progressCb;
    //         }
    //
    //         xhr.send(formData);
    //     });
    // }
}

export default Ajax;