import React from 'react';
import { Settings } from '../service/Settings';
import { formatFileSize, getAllowedTypesString } from '../service/Files';
import addIcon from '../img/window-close-regular.svg';
import uploadIconDis from '../img/arrow-right-solid.svg';
import uploadIcon from '../img/arrow-right-black.svg';

import '../css/Toolbar.css';

function Toolbar(props) {
    const icon = props.files.length === 0 ? uploadIconDis : uploadIcon;
    const uploadCss = props.files.length === 0 ? 'ToolbarUploadDisabled' : 'ToolbarUpload';
    const uploadFn = props.files.length === 0 ? null : props.uploadFiles;

    return <div className="Toolbar">
        <div className="ToolbarLeft">
            <div className="ToolbarBtn ToolbarAdd" onClick={props.addFiles}>
                <img src={addIcon} alt="add" />Select Files
            </div>
        </div>
        <div className="ToolbarMiddle">
            Accepted Formats: {getAllowedTypesString()}<br />
            Max Size Per File: {formatFileSize(Settings.bytesPerFile)}
        </div>
        <div className="ToolbarRight">
            <div className={"ToolbarBtn " + uploadCss} onClick={uploadFn}>
                <img src={icon} alt="upload" />Upload Files
            </div>
        </div>
    </div>;
}

export default Toolbar;