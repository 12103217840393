import React, {Component} from 'react';
import SigninExisting from './SigninExisting';
import SigninCreate from './SigninCreate';
import FileIcon from '../img/file-solid.svg';
import UploadIcon from '../img/upload-solid.svg';
import ThumbIcon from '../img/thumbs-up-solid.svg';
import '../css/Signin.css';

class Signin extends Component {
    constructor(props) {
        super(props);
        
        this.state = {create:false};
        this.baseDomRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { user } = this.props;

        if (typeof user === 'string' && user !== '') {
            this.baseDomRef.current.style.opacity = 0;
        }
    }

    signIn(user, staySignedIn) {
        if (user !== '') {
            this.props.signIn(user, staySignedIn);
        }
    }

    showCreate() {
        this.props.clearError();
        this.setState({create: true});
    }

    showExisting() {
        this.props.clearCreateError();
        this.setState({create: false});
    }

    render() {
        const { initialized, title, hide, error, createError, createAccount } = this.props;
        const { create } = this.state;

        return <div ref={this.baseDomRef} className="SigninArea" onTransitionEnd={hide}>
            <div className="SigninLeft">
                <div>
                    <span>{title}</span><br />
                    <span className="SigninTitle">Secure Document<br />Uploader</span>
                    <div className="SigninDivide"></div>
                    <table className="SigninInfo" cellSpacing="0">
                        <tbody>
                        <tr>
                            <td><img src={FileIcon} alt="file" width="30" height="40" /></td>
                            <td>Select the files you wish to upload</td>
                        </tr>
                        <tr>
                            <td><img src={UploadIcon} alt="upload" width="40" height="40" /></td>
                            <td>When you're ready, upload the files{initialized && ` to ${title}`}</td>
                        </tr>
                        <tr>
                            <td><img src={ThumbIcon} alt="thumb" width="40" height="40" /></td>
                            <td>You're done!</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="SigninRight">
                {initialized &&
                    (create
                        ? <SigninCreate showExisting={() => this.showExisting()}
                                        createAccount={createAccount}
                                        error={createError} />
                        : <SigninExisting showCreate={() => this.showCreate()}
                                          signIn={(u, p) => this.signIn(u, p)}
                                          error={error}
                        />)
                }
            </div>
        </div>;
    }
}

export default Signin;