import React, { Component } from 'react';
import errIcon from '../img/exclamation-triangle.svg';
import '../css/InvalidFiles.css';

class InvalidFiles extends Component {
    constructor(props) {
        super(props);

        this.baseDomRef = React.createRef();
        this.keyFn = (e) => this.handleKeys(e);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyFn);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyFn);
    }

    handleKeys(evt) {
        switch (evt.key) {
            case 'Escape':
            case 'Enter':
                this.close();
                break;

            default:
                break;
        }
    }

    close() {
        this.baseDomRef.current.style.opacity = 0;
    }

    render() {
        const { invalid, zeroSize, tooMany, tooLarge, fileTooLarge, close } = this.props;
        const files = [].concat(invalid, zeroSize, tooMany, tooLarge, fileTooLarge);
        const msg = [];

        if (invalid.length > 0) {
            msg.push('One or more files you selected are of an unsupported type.');
        }

        if (zeroSize.length > 0) {
            msg.push('One or more files you selected are empty.', 'File size must be greater than 0.');
        }

        if (tooMany.length > 0) {
            msg.push('Maximum number of files per upload has been exceeded.');
        }

        if (tooLarge.length > 0) {
            msg.push('Maximum size of files per upload has been exceeded.');
        }

        if (fileTooLarge.length > 0) {
            msg.push('One or more files you selected exceeds maximum file size.');
        }

        return <div ref={this.baseDomRef} className="InvalidFilesMask" onTransitionEnd={close}>
            <div className="InvalidFiles">
                <div className="InvalidFilesTitle">
                    <img src={errIcon} alt="error" />
                    There has been an error
                </div>
                <div className="InvalidFilesInfo">
                    {msg.map((m, ix) =>
                        <div key={ix}>{m}</div>
                    )}
                    <div>These files will not be added to your upload queue.</div>
                </div>
                <div className="InvalidFilesContent">
                    {files.map((file) =>
                        <div key={file.name}>{file.name}</div>
                    )}
                </div>
                <div className="InvalidFilesFooter">
                    <input type="button" value="I Understand" onClick={() => this.close()}/>
                </div>
            </div>
        </div>;
    }
}

export default InvalidFiles;