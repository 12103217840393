import React, { Component } from "react";
import Cookie from "../service/Cookie";

class SigninExisting extends Component{
    constructor(props) {
        super(props);

        const user = Cookie.get('username');

        this.state = {
            staySignedIn: false,
            user: user,
            captchad: false
        };

        this.recaptchaRef = React.createRef();
    }

    componentDidMount() {
        window.gtag('config', 'G-FB09WY2WQP', {
            'page_title': 'SignInAccount',
            'page_path': '/signinAccount'
        });

        this.renderCaptcha();
    }

    renderCaptcha() {
        if (   !window.grecaptcha
            || !window.grecaptcha.render
        ) {
            window.setTimeout(() => this.renderCaptcha(), 100);
            return;
        }

        window.grecaptcha.render(this.recaptchaRef.current, {
            sitekey: '6LeZiK8UAAAAAF9WBV84776hiQraMtBqe0W77KxT',
            callback: () => this.setState({captchad:true})
        });
    }

    render() {
        const { user, staySignedIn, captchad } = this.state;
        const { showCreate, signIn } = this.props;
        let { error } = this.props;

        switch (error) {
            case 'INVALIDACCOUNT':
                error = <span>
                            Email address not found.  You either<br />
                            made a typo or don't have an account.<br />
                            Click <button onClick={showCreate}>here</button> to create one.
                        </span>;
                break;
            default:
                break;
        }

        const emailError = typeof user === 'string'
            && user !== ''
            && user.match(/^[^@]+@[^@]+\.[^@]+$/) === null;
        const emailErrorText = emailError ? 'Invalid Email Address' : <br />;

        const loginDisabled = typeof user !== 'string' || user === '' || emailError || !captchad;

        return <div style={{display:'inline-block', margin:'0 30px'}}>
            <div className="SigninInstr">Login below. If you don't have an account,<br />you can create one <button onClick={showCreate}>here</button>.</div>
            <table className="SigninEmail" cellSpacing="0">
                <tbody>
                <tr>
                    <td>
                        <div className="SigninUserField">
                            <div>Email Address</div>
                            <input type="email" className={emailError ? 'Invalid' : ''} value={user === null ? '' : user} onChange={(e) => this.setState({user: e.target.value})} />
                            <div className="Error">{emailErrorText}</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="checkbox" checked={staySignedIn} onChange={(e) => this.setState({staySignedIn:e.target.checked})} style={{transform:'scale(1.75)', marginRight:'10px'}} /> Keep me signed in
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="SigninUserField">
                            <div>Are you a robot?</div>
                            <div ref={this.recaptchaRef} style={{marginTop:'9px'}}></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input className={loginDisabled ? 'SigninLoginDisabled' : 'SigninLogin'}
                               type="button"
                               value="Login"
                               disabled={loginDisabled}
                               onClick={() => signIn(user, staySignedIn)}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            {error && <div className="SigninError">{error}</div>}
        </div>;
    }
}

export default SigninExisting;
