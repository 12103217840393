import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import Toolbar from './Toolbar';
import Signin from './Signin';
import InvalidFiles from './InvalidFiles';
import AccountModal from './AccountModal';
import ValidateToken from './ValidateToken';
import ListView from './ListView';
import DropZone from './DropZone';
import Notify from './Notify';
import Upload from './Upload';
import { Settings } from '../service/Settings';
import Ajax from '../service/Ajax';
import Cookie from '../service/Cookie';
import { getTotalFileSize, getFileInputFiles, getDragDropFiles, getDragDropTypes, getFileTypeFromMime, validateTypes, fileInQueue, getAllowedMimesString } from '../service/Files';
import Logo from '../img/i3_logomark.svg';

import '../css/App.css';

class App extends Component {
    // recaptcha keys
    // label: i3screen_secureupload
    // site key: 6LeZiK8UAAAAAF9WBV84776hiQraMtBqe0W77KxT
    // secret key: 6LeZiK8UAAAAAHeUuNxVKWLT8WS-4A0HQjOPcu5o

    constructor(props) {
        super(props);

        const user = Cookie.get('username');
        const key = new URLSearchParams(window.location.search).get("key");

        this.state = {
            initialized: false,
            key: key,
            title: 'Initializing...',
            signinVis: user === null,
            signinErr: null,
            createError: null,
            user: user,
            dropping: false,
            droppingInvalid: false,
            files: [],
            sortCol: 'name',
            sortDir_name: 'Asc',
            sortDir_type: 'Asc',
            sortDir_size: 'Asc',
            notify: null,
            zeroSizeFiles: [],
            invalidFiles: [],
            tooManyFiles: [],
            tooLargeFiles: [],
            fileTooLargeFiles: [],
            uploading: false,
            bytesSent: 0,
            bytesTotal: 0,
            uploaded: false,
            uploadError: null,
            accountModal: null,
            accountModalUser: null,
            token: new URLSearchParams(window.location.search).get('token')
        };

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('beforeunload', (e) => {
            if (   this.state.user
                && this.state.files.length > 0
                && (!this.state.uploaded
                || this.state.uploadError)
            ) {
                e.preventDefault();
                e.returnValue = false;
            }
        });

        if (this.state.token !== null) {
            this.validateToken();
        }

        this.initialize();
    }

    initialize() {
        const { key } = this.state;

        const query = ["host=" + encodeURIComponent(window.location.host)];
        if (key) {
            query.push("key=" + encodeURIComponent(key));
        }

        Ajax.get(
            '/api/upload/initialize?' + query.join('&')
        ).then(response => {
            const root = document.documentElement;
            root.style.setProperty("--accent-color-primary", response.theme.primary_accent);
            root.style.setProperty("--accent-color-secondary", response.theme.secondary_accent);
            root.style.setProperty("--button-color", response.theme.button);
            root.style.setProperty("--button-color-hover", response.theme.button_hover);
            root.style.setProperty("--button-color-press", response.theme.button_press);
            root.style.setProperty("--button-text-color", response.theme.button_text);

            this.setState({
                initialized: true,
                title: response.title
            });
        }).catch(error => {
            this.setState({
                signinErr: <span>Server error.</span>,
                notify: 'Initialization failed.'
            });
        });
    }

    validateToken() {
        Ajax.post(
            '/api/upload/token',
            'application/json;charset=UTF-8',
            JSON.stringify({
                token: this.state.token
            })
        ).then(response => {
            this.hideTokenValidate();
            this.setState({
                user: response.email,
                accountModal: 'activationSuccess'
            });
            window.history.replaceState(null, 'killToken', '/');
        }).catch(() => {
            this.hideTokenValidate();
            this.setState({accountModal:'activationFail'});
            window.history.replaceState(null, 'killToken', '/');
        });
    }

    chooseFiles(evt) {
        evt.preventDefault();
        this.fileInputRef.current.click();
    }

    addFiles() {
        this.processFiles(
            this.collateFiles(
                getFileInputFiles(this.fileInputRef.current)
            )
        );

        this.fileInputRef.current.value = '';
    }

    removeFiles() {
        if (this.state.files.length > 0) {
            this.setState({files: []});
        }
    }

    removeFile(evt, name) {
        const newFiles = this.state.files;

        evt.preventDefault();

        for (let i = 0; i < newFiles.length; i ++) {
            if (newFiles[i].name === name) {
                newFiles.splice(i, 1);
                break;
            }
        }

        this.setState({files: newFiles});
    }

    processFiles(collated) {
        const { valid, zeroSize, invalid, tooMany, tooLarge, fileTooLarge } = collated;

        if (valid.length > 0) {
            this.setState({files: this.state.files.concat(valid)});
        }

        if (zeroSize.length > 0) {
            this.setState({zeroSizeFiles: this.state.zeroSizeFiles.concat(zeroSize)});
        }

        if (invalid.length > 0) {
            this.setState({invalidFiles: this.state.invalidFiles.concat(invalid)});
        }

        if (tooMany.length > 0) {
            this.setState({tooManyFiles: this.state.tooManyFiles.concat(tooMany)});
        }

        if (tooLarge.length > 0) {
            this.setState({tooLargeFiles: this.state.tooLargeFiles.concat(tooLarge)});
        }

        if (fileTooLarge.length > 0) {
            this.setState({fileTooLargeFiles: this.state.fileTooLargeFiles.concat(fileTooLarge)});
        }
    }

    onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        if (!this.state.dropping) {
            this.setState({
                dropping: true,
                droppingInvalid: !validateTypes(getDragDropTypes(evt))
            });
        }
    }

    onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        if (this.state.dropping) {
            this.setState({
                dropping: false,
                droppingInvalid: false
            });
        }
    }

    onDrop(evt) {
        evt.preventDefault();

        this.setState({
            dropping: false,
            droppingInvalid: false
        });

        this.processFiles(
            this.collateFiles(
                getDragDropFiles(evt)
            )
        );
    }

    collateFiles(files) {
        const collated = {
            valid: [],
            invalid: [],
            zeroSize: [],
            tooMany: [],
            tooLarge: [],
            fileTooLarge: []
        };

        for (let i = 0; i < files.length; i ++) {
            if (fileInQueue(files[i].name, this.state.files)) {
                continue;
            }

            if (files[i].size === 0) {
                collated.zeroSize.push(files[i]);
                continue;
            }

            if (!getFileTypeFromMime(files[i].type)) {
                collated.invalid.push(files[i]);
                continue;
            }

            if (this.state.files.length + collated.valid.length >= Settings.filesPerUpload) {
                collated.tooMany.push(files[i]);
                continue;
            }

            if (files[i].size > Settings.bytesPerFile) {
                collated.fileTooLarge.push(files[i]);
                continue;
            }

            let size = getTotalFileSize(this.state.files) + getTotalFileSize(collated.valid);
            if (size + files[i].size > Settings.bytesPerUpload) {
                collated.tooLarge.push(files[i]);
                continue;
            }

            collated.valid.push(files[i]);
        }

        return collated;
    }

    setSort(col) {
        if (col === this.state.sortCol) {
            const key = 'sortDir_' + col;
            const dir = this.state['sortDir_' + col];
            const newDir = {};
            newDir[key] = dir === 'Asc' ? 'Desc' : 'Asc';
            this.setState(newDir);
        } else {
            this.setState({sortCol: col});
        }
    }

    signIn(email, persist) {
        if (window.grecaptcha.getResponse() === '') {
            return;
        }

        this.clearSigninError();

        Ajax.get(
            '/api/upload/users/email/' + encodeURI(email)
        ).then(response => {
            if (response.validated !== true) {
                this.setState({
                    accountModal: 'unvalidated',
                    accountModalUser: email
                });
                return;
            }

            if (persist) {
                Cookie.set('username', email);
            } else {
                Cookie.clear('username');
            }

            this.setState({
                user: email,
                notify: 'Your account has been successfully validated.'
            });
        }).catch(error => {
            switch (error.status) {
                case 404:
                case 422:
                    this.setState({
                        signinErr: 'INVALIDACCOUNT',
                        notify: 'Invalid account email.'
                    });
                    break;

                default:
                    this.setState({
                        signinErr: <span>Server error.</span>,
                        notify: 'Server error.'
                    });
                    break;
            }
        });
    }

    createAccount(email, name, phone, company) {
        if (window.grecaptcha.getResponse() === '') {
            return;
        }

        this.clearCreateError();

        Ajax.post(
            '/api/upload/users',
            'application/json;charset=UTF-8',
            JSON.stringify({
                email: email,
                name: name,
                phone: phone.replace(/[^0-9]/g, ''),
                company: company
            })
        ).then(() => {
            this.setState({
                accountModalUser: email,
                accountModal: 'created'
            });
        }).catch(error => {
            switch (error.status) {
                case 409:
                    this.setState({
                        createError: <span>Account is already registered.</span>,
                        notify: 'Account already registered.'
                    });
                    break;

                default:
                    this.setState({
                        createError: <span>Server error.</span>,
                        notify: 'Server error.'
                    });
                    break;
            }
        });
    }

    cancelUpload() {
        this.setState({
            uploading: false,
            bytesSent: 0,
            bytesTotal: 0,
            uploaded: false,
            uploadError: null
        });
    }

    retryUpload() {
        this.setState({
            bytesSent: 0,
            bytesTotal: 0,
            uploaded: false,
            uploadError: null
        });
        this.upload();
    }

    logout(evt) {
        evt.preventDefault();

        Cookie.clear('username');

        this.setState({
            signinVis: true,
            user: null,
            dropping: false,
            droppingInvalid: false,
            files: [],
            sortCol: 'name',
            sortDir_name: 'Asc',
            sortDir_type: 'Asc',
            sortDir_size: 'Asc',
            notify: null,
            invalidFiles: [],
            uploading: false,
            bytesSent: 0,
            bytesTotal: 0,
            uploaded: false,
            uploadError: null
        });
    }

    clearSigninError() {
        this.setState({signinErr: null});
    }

    clearCreateError() {
        this.setState({createError: null});
    }

    hideSignin() {
        this.setState({signinVis: false});
    }

    hideTokenValidate() {
        this.setState({token:null});
    }

    upload() {
        const { key, user, files } = this.state;
        const formData = new FormData();

        const query = ["host=" + encodeURIComponent(window.location.host)];
        if (key) {
            query.push("key=" + encodeURIComponent(key));
        }

        formData.append('email', user);

        for (let i = 0; i < files.length; i ++) {
            formData.append('userfile[]', files[i]);
        }

        this.setState({uploading: true});

        Ajax.post(
            '/api/upload/files?' + query.join('&'),
            null,
            formData,
            (e) => {this.progress(e)}
        ).then(() => {
            this.setState({
                notify: 'Files were uploaded successfully',
                uploaded: true
            });
        }).catch(() => {
            this.setState({
                uploaded: true,
                uploadError: 'An error occurred while uploading files'
            });
        });
    }

    progress(evt) {
        if (evt.lengthComputable) {
            this.setState({
                bytesSent: evt.loaded,
                bytesTotal: evt.total
            });
        }
    }

    render() {
        const { initialized, title, user, dropping, droppingInvalid, files, sortCol, signinVis, signinErr, createError, notify, invalidFiles, zeroSizeFiles, tooManyFiles, tooLargeFiles, fileTooLargeFiles, bytesSent, bytesTotal, uploading, uploaded, uploadError, accountModal, accountModalUser, token } = this.state;
        const sortDir = this.state['sortDir_' + sortCol];

        return <Router>
            <Switch>
                <Route exact path="/">
                    <Fragment>
                        <input ref={this.fileInputRef}
                               type="file"
                               multiple={true}
                               accept={getAllowedMimesString()} //"image/png, image/jpeg, image/tiff, application/pdf, application/x-diskcopy"
                               onChange={() => this.addFiles()}
                               style={{display:'none'}}
                        />
                        <div className="Header">
                            <div className="HeaderTitle">
                                <img src={Logo} alt="logo" />
                                <div>
                                    <div>Secure Document Uploader</div>
                                    <div className="HeaderSubTitle">{title}</div>
                                </div>
                            </div>
                            <div className="HeaderUser">
                                {user}
                                <button onClick={(e) => this.logout(e)}>Logout</button>
                            </div>
                        </div>
                        {
                            user && initialized && <Toolbar files={files}
                                             addFiles={(e) => this.chooseFiles(e)}
                                             uploadFiles={(e) => this.upload(e)}
                            />
                        }
                        {
                            user && initialized && (dropping
                                ? <DropZone dragLeave={(e) => this.onDragLeave(e)}
                                            drop={(e) => this.onDrop(e)}
                                            invalid={droppingInvalid}
                                />
                                : <ListView files={files}
                                            sortCol={sortCol}
                                            sortDir={sortDir}
                                            setSort={(col) => this.setSort(col)}
                                            dragOver={(e) => this.onDragOver(e)}
                                            remove={(e, name) => this.removeFile(e, name)}
                                            removeAll={() => this.removeFiles()}
                                            addFiles={(e) => this.chooseFiles(e)}
                                />)
                        }
                        {
                            (invalidFiles.length > 0 || zeroSizeFiles.length > 0 || tooManyFiles.length > 0 || tooLargeFiles.length > 0 || fileTooLargeFiles.length > 0 )
                                && <InvalidFiles invalid={invalidFiles}
                                                 zeroSize={zeroSizeFiles}
                                                 tooMany={tooManyFiles}
                                                 tooLarge={tooLargeFiles}
                                                 fileTooLarge={fileTooLargeFiles}
                                                 close={() => this.setState({invalidFiles:[], tooManyFiles:[], tooLargeFiles:[], fileTooLargeFiles:[]})}
                            />
                        }
                        {
                            uploading && <Upload sent={bytesSent}
                                                 total={bytesTotal}
                                                 uploaded={uploaded}
                                                 error={uploadError}
                                                 close={() => this.cancelUpload()}
                                                 retry={() => this.retryUpload()}
                                                 clear={() => this.removeFiles()}
                            />
                        }
                        {
                            signinVis && <Signin initialized={initialized}
                                                 title={title}
                                                 user={user}
                                                 error={signinErr}
                                                 createError={createError}
                                                 clearError={() => this.clearSigninError()}
                                                 clearCreateError={() => this.clearCreateError()}
                                                 hide={() => this.hideSignin()}
                                                 signIn={(e, p) => this.signIn(e, p)}
                                                 createAccount={(e, n, p, c) => this.createAccount(e, n, p, c)}
                            />
                        }
                        {
                            accountModal && <AccountModal type={accountModal}
                                                          user={accountModalUser}
                                                          close={() => this.setState({accountModal:null, accountModalUser:null})}
                            />
                        }
                        {
                            token && <ValidateToken close={() => this.hideTokenValidate()} />
                        }
                        {
                            notify && <Notify notify={notify} close={() => this.setState({notify: null})}/>
                        }
                    </Fragment>
                </Route>
                <Route path="*">
                    <PageNotFound />
                </Route>
            </Switch>
        </Router>;
    }
}

export default App;
