import React from 'react';
import errorImg from '../img/exclamation-triangle.svg';
import '../css/Signin.css';

export default function PageNotFound(props) {
    return <div className="SigninArea">
        <div className="SigninLeft">
            <div>
                <span>i3screen</span><br />
                <span className="SigninTitle">404</span>
                <div className="SigninDivide"></div>
                <div className="SigninSubtitle">Page Not Found</div>
            </div>
        </div>
        <div className="SigninRight PageNotFound">
            <div style={{margin:'0 40px'}}>
                <img src={errorImg} width="200" style={{marginBottom:'30px'}} alt="Error" />
                <div className="SigninTitle">Oops!</div>
                <div className="SigninSubtitle">We can't find that page.</div>
                <div className="PageNotFoundMsg">The link you followed may be wrong or that page no longer exists.</div>
                <button className="SigninLogin" onClick={() => window.location.href='/'}>Go to Secure Document Uploader</button>
            </div>
        </div>
    </div>;
}