import React from "react";
import DropImg from '../img/arrow-alt-circle-down-regular.svg';
import ErrorImg from '../img/exclamation-triangle.svg';
import '../css/DropZone.css';

function DropZone(props) {
    const { dragLeave, drop, invalid } = props;
    const className = invalid
        ? 'DropZoneContent DropZoneContentInvalid'
        : 'DropZoneContent';

    return <div className="DropZone"
                onDragOver={(e) => e.preventDefault()}
                onDragLeave={dragLeave}
                onDrop={drop}
        >
        <div className={className}>
            <div style={{textAlign:'center'}}>
                <img src={invalid ? ErrorImg : DropImg} alt="dropImg" />
                <div>Drop files anywhere to add them to your queue</div>
                {invalid && <div className="DropZoneWarning">One or more files are not supported</div>}
            </div>
        </div>
    </div>;
}

export default DropZone;