import React from "react";
import {formatFileSize, getAllowedTypesString} from "../service/Files";
import {Settings} from "../service/Settings";

export default function ListViewEmpty(props) {
    const { addFiles, dragOver } = props;

    return <div className="EmptyList" onDragOver={dragOver}>
        <div className="EmptyListArea">
            <div>
                <div className="EmptyListTitle">Your Upload Queue is empty.</div>
                <div className="EmptyListBody">
                    You can either drag and drop files here or <button onClick={addFiles}>browse for files</button> on
                    your computer.<br/>
                    Once you've added files to the queue, click the Upload button to send them to the MRO.
                    <div className="EmptyListBodyMessage">
                        Please do not upload NonDOT CCF documents.
                    </div>
                </div>
                <div className="EmptyListFooter">
                    Accepted Formats: {getAllowedTypesString()}<br/>
                    Max Size Per File: {formatFileSize(Settings.bytesPerFile)}
                </div>
            </div>
        </div>
    </div>;
}