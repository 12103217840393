import React, { Component } from "react";

class SigninCreate extends Component{
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            name: '',
            phone: '',
            company: '',
            captchad: false
        };

        this.recaptchaRef = React.createRef();
    }

    componentDidMount() {
        window.gtag('config', 'G-FB09WY2WQP', {
            'page_title': 'CreateAccount',
            'page_path': '/createAccount'
        });

        this.renderCaptcha();
    }

    renderCaptcha() {
        if (   !window.grecaptcha
            || !window.grecaptcha.render
        ) {
            window.setTimeout(() => this.renderCaptcha(), 100);
            return;
        }

        window.grecaptcha.render(this.recaptchaRef.current, {
            sitekey: '6LeZiK8UAAAAAF9WBV84776hiQraMtBqe0W77KxT',
            callback: () => this.setState({captchad:true})
        });
    }

    render() {
        const { email, name, phone, company, captchad } = this.state;
        const { showExisting, error, createAccount } = this.props;

        const emailError = email !== ''
            && email.match(/^[^@]+@[^@]+\.[^@]+$/) === null;
        const emailErrorText = emailError ? 'Invalid Email Address' : <br />;

        const nameError = name !== '' && name.length > 200;
        const nameErrorText = nameError ? 'Maximum 200 Characters' : <br />;

        const phoneError = phone !== ''
            && phone.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/) === null;
        const phoneErrorText = phoneError ? 'Invalid Phone Number' : <br />;

        const companyError = company !== '' && company.length > 200;
        const companyErrorText = companyError ? 'Maximum 200 Characters' : <br />;

        const loginDisabled = email === '' || emailError
            || name === '' || nameError
            || phone === '' || phoneError
            || company === '' || companyError
            || !captchad;

        return <div style={{display:'inline-block', margin:'0 30px'}}>
            <div style={{marginBottom:'5px'}}>To start, let's verify some information:</div>
            <div className="SigninCreateInstr">Already have an account? Sign in <button onClick={showExisting}>here</button>.</div>
            <table className="SigninUser" cellSpacing="0">
                <tbody>
                    <tr>
                        <td colSpan="2">
                            <div className="SigninUserField">
                                <div>Email Address</div>
                                <input type="email" className={emailError ? 'Invalid' : ''} onChange={(e) => this.setState({email: e.target.value})} />
                                <div className="Error">{emailErrorText}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="SigninUserField">
                                <div>Full Name</div>
                                <input ref="name"
                                       type="text"
                                       className={nameError ? 'Invalid' : ''}
                                       onChange={(e) => this.setState({name: e.target.value})}
                                />
                                <div className="Error">{nameErrorText}</div>
                            </div>
                        </td>
                        <td>
                            <div className="SigninUserField">
                                <div>Phone Number</div>
                                <input ref="phone" type="text" className={phoneError ? 'Invalid' : ''} onChange={(e) => this.setState({phone: e.target.value})} />
                                <div className="Error">{phoneErrorText}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <div className="SigninUserField">
                                <div>Company Name</div>
                                <input ref="company" type="text" className={companyError ? 'Invalid' : ''} onChange={(e) => this.setState({company: e.target.value})} />
                                <div className="Error">{companyErrorText}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <div className="SigninUserField">
                                <div>Are you a robot?</div>
                                <div ref={this.recaptchaRef} style={{marginTop:'9px'}}></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <input className={loginDisabled ? 'SigninLoginDisabled' : 'SigninLogin'}
                                   type="button"
                                   value="Create Account"
                                   disabled={loginDisabled}
                                   onClick={() => createAccount(email, name, phone, company)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            {error && <div className="SigninError">{error}</div>}
        </div>;
    }
}

export default SigninCreate;
