import React, { Component } from 'react';
import errorImg from '../img/exclamation-triangle.svg';
import successImg from '../img/check-solid-green-v2.svg';
import '../css/AccountModal.css';

class AccountModal extends Component {
    constructor(props) {
        super(props);

        this.baseDomRef = React.createRef();
        this.keyFn = (e) => this.handleKeys(e);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyFn);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyFn);
    }

    handleKeys(evt) {
        switch (evt.key) {
            case 'Escape':
            case 'Enter':
                this.close();
                break;

            default:
                break;
        }
    }

    close() {
        this.baseDomRef.current.style.opacity = 0;
    }

    render() {
        const { type, user, close } = this.props;
        let img, title, info, btn;

        switch (type) {
            case 'created':
                img = successImg;
                title = 'Your account has been created';
                info = <div>An email has been sent to your email address at {user}.  Click the link in the email to activate your account.</div>;
                btn = 'I Understand';
                break;

            case 'unvalidated':
                img = errorImg;
                title = 'Your account has not been activated';
                info = <div>An email was sent to your email address at {user}.  Click the link in the email to activate your account.</div>;
                btn = 'I Understand';
                break;

            case 'activationFail':
                img = errorImg;
                title = 'Account activation failed';
                info = <div>Please contact support by emailing <a href="mailto:support@i3screen.com">support@i3screen.com</a></div>;
                btn = 'I Understand';
                break;

            case 'activationSuccess':
                img = successImg;
                title = 'Your account has been activated';
                info = null;
                btn = 'Start Uploading Files';
                break;

            default:
                img = errorImg;
                title = 'Server Error';
                info = <div>Server error.</div>;
                btn = 'I Understand';
                break;
        }

        return <div ref={this.baseDomRef} className="AccountModalMask" onTransitionEnd={close}>
            <div className="AccountModal">
                <div className="AccountModalTitle">
                    <img src={img} alt="successOrFail" />
                    {title}
                </div>
                {
                    info && <div className="AccountModalContent">
                        <div>{info}</div>
                    </div>
                }
                <div className="AccountModalFooter">
                    <input type="button" value={btn} onClick={() => this.close()} />
                </div>
            </div>
        </div>;
    }
}

export default AccountModal;