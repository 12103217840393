import React, { Component } from 'react';
import checkIcon from '../img/check-solid.svg';
import '../css/Notify.css';

class Notify extends Component {
    constructor(props) {
        super(props);

        this.timer = null;
        this.baseDomRef = React.createRef();
    }

    componentDidMount() {
        this.display();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.display();
    }

    display() {
        if (this.timer !== null) {
            window.clearTimeout(this.timer);
            this.timer = null;
        }

        this.baseDomRef.current.style.opacity = 1;
        this.delay();
    }

    delay() {
        const self = this;
        this.timer = window.setTimeout(function() {self.fade()}, 2000);
    }

    fade() {
        this.baseDomRef.current && (this.baseDomRef.current.style.opacity = 0);
    }

    close() {
        if (this.baseDomRef.current.style.opacity === '0') {
            this.props.close();
        }
    }

    render() {
        const { notify } = this.props;
        return <div ref={this.baseDomRef} className="Notify" onTransitionEnd={() => this.close()}>
            <img src={checkIcon} alt="mark" />
            {notify}
        </div>;
    }
}

export default Notify;