export const Settings = {
    filesPerUpload: 100,
    bytesPerFile: 100 * 1024 * 1024, // 100 MB
    bytesPerUpload: 200 * 1024 * 1024, // 200 MB
    allowedTypes: {
        png:  ['image/png'],
        jpeg: ['image/jpeg'],
        tiff: ['image/tiff'],
        pdf:  ['application/pdf']
    },
    statusDelay: 500,
    statusFade: 250
};