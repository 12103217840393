import React from 'react';
import '../css/ValidateToken.css';

export default function ValidateToken() {
    return <div className="ValidateTokenMask">
        <div className="ValidateToken">
            <div className="ValidateTokenTitle">
                Activating your account...
            </div>
        </div>
    </div>;
};