import { Settings } from './Settings';

export function formatFileSize(size) {
    let str;

    if (size < 1024) {
        return size + ' bytes';
    }

    size /= 1024;
    if (size < 1024) {
        str = size.toFixed(2).replace('.00', '');
        return str + ' KB';
    }

    size /= 1024;
    str = size.toFixed(2).replace('.00', '');
    return str + ' MB';
}

export function getTotalFileSize(files) {
    let i, size = 0;

    for (i = 0; i < files.length; i ++) {
        size += files[i].size;
    }

    return size;
}

export function getFileInputFiles(input) {
    let i, files = [];

    for (i = 0; i < input.files.length; i ++) {
        files.push(input.files[i]);
    }

    return files;
}

export function getDragDropFiles(evt) {
    let i, files = [];

    if (evt.dataTransfer.items) {
        for (i = 0; i < evt.dataTransfer.items.length; i ++) {
            if (evt.dataTransfer.items[i].kind === 'file') {
                files.push(evt.dataTransfer.items[i].getAsFile());
            }
        }
    } else {
        for (i = 0; i < evt.dataTransfer.files.length; i ++) {
            files.push(evt.dataTransfer.files[i]);
        }
    }

    return files;
}

export function getDragDropTypes(evt) {
    let i, types = [];

    if (evt.dataTransfer.items) {
        for (i = 0; i < evt.dataTransfer.items.length; i ++) {
            if (   evt.dataTransfer.items[i].kind === 'file'
                && types.indexOf(evt.dataTransfer.items[i].type) < 0
            ) {
                types.push(evt.dataTransfer.items[i].type);
            }
        }
    } else {
        for (i = 0; i < evt.dataTransfer.files.length; i ++) {
            if (types.indexOf(evt.dataTransfer.items[i].type) < 0) {
                types.push(evt.dataTransfer.files[i].type);
            }
        }
    }

    return types;
}

export function getFileTypeFromMime(mime) {
    for (let type in Settings.allowedTypes) {
        if (   Settings.allowedTypes.hasOwnProperty(type)
            && Settings.allowedTypes[type].indexOf(mime) >= 0
        ) {
            return type;
        }
    }

    return null;
}

export function validateTypes(types) {
    for (let i = 0; i < types.length; i ++) {
        if (!getFileTypeFromMime(types[i])) {
            return false;
        }
    }

    return true;
}

export function fileInQueue(name, files) {
    for (let i = 0; i < files.length; i ++) {
        if (files[i].name === name) {
            return true;
        }
    }

    return false;
}

export function getAllowedMimesString() {
    let type, mimes = [];

    for (type in Settings.allowedTypes) {
        if (Settings.allowedTypes.hasOwnProperty(type)) {
            mimes = mimes.concat(Settings.allowedTypes[type]);
        }
    }

    return mimes.join(', ');
}

export function getAllowedTypesString() {
    let type, types = [];

    for (type in Settings.allowedTypes) {
        if (Settings.allowedTypes.hasOwnProperty(type)) {
            types.push(type.toUpperCase());
        }
    }

    return types.join(', ');
}

export function sortFiles(files, prop, dir) {
    return files.concat()
        .sort(
            dir === 'Desc'
                ? function(a, b) {
                    return typeof a[prop] === 'string'
                        ? (b[prop].toLowerCase() < a[prop].toLowerCase() ? -1 : 1)
                        : (b[prop] < a[prop] ? -1 : 1);
                }
                : function(a, b) {
                    return typeof a[prop] === 'string'
                        ? (b[prop].toLowerCase() < a[prop].toLowerCase() ? 1 : -1)
                        : (b[prop] < a[prop] ? 1 : -1);
                }
        );
}