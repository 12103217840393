import React, { Component } from 'react';
import { Settings } from '../service/Settings';
import errorImg from '../img/exclamation-triangle.svg';
import successImg from '../img/check-solid-green-v2.svg';
import '../css/Upload.css';

class Upload extends Component {
    constructor(props) {
        super(props);

        this.size = 0;
        this.thickness = 30;
        this.timer = null;
        this.startTime = null;
        this.finished = false;

        this.baseDomRef = React.createRef();
        this.canvasRef = React.createRef();
        this.errorImgRef = React.createRef();
        this.successImgRef = React.createRef();

        this.keyFn = (e) => this.handleKeys(e);
    }

    componentDidMount() {
        window.gtag('event', 'SecureUploader', {
            'UploaderAction': 'UploadBegin',
            'UploaderValue': this.props.total
        });

        window.addEventListener('keydown', this.keyFn);

        const cnv = this.canvasRef.current;

        this.ctx = cnv.getContext('2d');
        this.ctx.lineJoin = 'round';

        this.size = cnv.width < cnv.height
            ? cnv.width
            : cnv.height;

        this.offset = {
            x: (cnv.width - this.size) * 0.5,
            y: (cnv.height - this.size) * 0.5
        };

        this.center = {
            x: cnv.width * 0.5,
            y: cnv.height * 0.5
        };

        this.draw();
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyFn);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (   this.timer === null
            && !this.finished
        ) {
            this.draw();
        }
    }

    handleKeys(evt) {
        if (this.props.uploaded) {
            switch (evt.key) {
                case 'Escape':
                    this.close();
                    break;

                case 'Enter':
                    if (this.props.error) {
                        this.retry();
                    } else {
                        this.close();
                    }
                    break;

                default:
                    break;
            }
        }
    }

    requestFrame(cb) {
        if (this.timer === null) {
            this.timer = window.requestAnimationFrame((time) => {
                this.timer = null;
                if (this.startTime === null) {
                    this.startTime = time;
                }
                cb(time, time - this.startTime);
            });
        }
    }

    cancelFrame() {
        if (this.timer !== null) {
            window.cancelAnimationFrame(this.timer);
            this.timer = null;
        }
        this.startTime = null;
    }

    draw() {
        if (this.props.uploaded) {
            window.gtag('event', 'SecureUploader', {
                'UploaderAction': 'Upload' + this.props.error ? 'Error' : 'Success',
                'UploaderValue': this.props.total
            });

            if (this.props.error) {
                this.requestFrame((t, d) => this.drawError(t, d));
            } else {
                this.requestFrame((t, d) => this.drawSuccess(t, d));
            }
        } else {
            this.drawProgress();
        }
    }

    drawInnerCircle() {
        this.ctx.beginPath();
        this.ctx.arc(
            this.center.x,
            this.center.y,
            this.size * 0.5 - this.thickness,
            0,
            Math.PI * 2
        );
        this.ctx.fillStyle = 'white';
        this.ctx.fill();
    }

    drawProgress() {
        const { sent, total } = this.props;
        const unit = total === 0 ? 0 : sent / total;
        const start = Math.PI * 1.5;

        this.ctx.clearRect(this.offset.x, this.offset.y, this.size, this.size);

        // progress bar
        this.ctx.beginPath();
        this.ctx.arc(
            this.center.x,
            this.center.y,
            this.size * 0.5 - this.thickness * 0.5,
            start,
            start + unit * Math.PI * 2
        );
        this.ctx.lineWidth = this.thickness;
        this.ctx.strokeStyle = '#ffba60';
        this.ctx.stroke();

        // outer circle
        this.ctx.beginPath();
        this.ctx.arc(
            this.center.x,
            this.center.y,
            this.size * 0.5 - 0.5,
            0,
            Math.PI * 2
        );
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = 'white';
        this.ctx.stroke();

        this.drawInnerCircle();

        // percent complete
        this.ctx.font = 'bold 75px Arial';
        const text = Math.floor(unit * 100).toString();
        const w1 = this.ctx.measureText(text).width;

        this.ctx.font = 'bold 30px Arial';
        const w2 = this.ctx.measureText('%').width;

        const w3 = w1 + w2;
        const left = this.center.x - w3 * 0.5;

        this.ctx.fillStyle = 'black';
        this.ctx.font = 'bold 75px Arial';
        this.ctx.fillText(text, left, this.center.y + 24);
        this.ctx.font = 'bold 30px Arial';
        this.ctx.fillText('%', left + w1, this.center.y - 7);
    }

    drawSuccess(time, delta) {
        if (delta < Settings.statusDelay) {
            this.requestFrame((t, d) => this.drawSuccess(t, d));
            return;
        }

        let unit = delta - Settings.statusDelay >= Settings.statusFade ? 1 : (delta - Settings.statusDelay) / Settings.statusFade;
        if (unit < 1) {
            this.requestFrame((t, d) => this.drawSuccess(t, d));
        } else {
            this.cancelFrame();
            this.finished = true;
        }

        this.ctx.globalAlpha = 1 - unit;
        this.drawProgress();

        this.ctx.globalAlpha = unit;
        this.drawInnerCircle();

        this.ctx.drawImage(
            this.successImgRef.current,
            this.center.x - 65, this.center.y - 65, 130, 130
        );

        this.ctx.globalAlpha = 1;
    }

    drawError(time, delta) {
        if (delta < Settings.statusDelay) {
            this.requestFrame((t, d) => this.drawError(t, d));
            return;
        }

        let unit = delta - Settings.statusDelay >= Settings.statusFade ? 1 : (delta - Settings.statusDelay) / Settings.statusFade;
        if (unit < 1) {
            this.requestFrame((t, d) => this.drawError(t, d));
        } else {
            this.cancelFrame();
            this.finished = true;
        }

        this.ctx.globalAlpha = 1 - unit;
        this.drawProgress();

        this.ctx.globalAlpha = unit;
        this.drawInnerCircle();

        this.ctx.drawImage(
            this.errorImgRef.current,
            this.center.x - 65, this.center.y - 77, 130, 130
        );

        this.ctx.globalAlpha = 1;
    }
    
    close() {
        this.cancelFrame();
        this.baseDomRef.current.style.opacity = 0;
    }

    retry() {
        this.cancelFrame();
        this.finished = false;
        this.props.retry();
    }

    uploadMore() {
        this.props.clear();
        this.close();
    }

    render() {
        const { close, uploaded, error } = this.props;
        const vis = uploaded ? 'visible' : 'hidden';
        const title = uploaded
            ? (error
                ? 'Upload Failed'
                : 'Upload Success')
            : 'Uploading Documents';
        const msg = uploaded
            ? (error
                ? 'The upload has failed. You can either try again or return to the file queue.'
                : 'The upload was successful.')
            : 'Uploading files';

        return <div ref={this.baseDomRef} className="Mask" onTransitionEnd={close}>
            <div className="Upload">
                <img ref={this.errorImgRef} src={errorImg} alt="error" />
                <img ref={this.successImgRef} src={successImg} alt="success" />
                <div className="UploadContent">
                    <div>{title}</div>
                    <canvas ref={this.canvasRef} width="296" height="296" />
                    <div className="UploadMessage" style={{visibility:vis}}>{msg}</div>
                    <div className="UploadControls" style={{visibility:vis}}>
                        {error ? <input className="UploadRetry" type="button" value="Retry Upload" onClick={() => this.retry()} />
                            : null}
                        {error ? <input className="UploadCancel" type="button" value="Cancel" onClick={() => this.close()} />
                            : <input className="UploadMore" type="button" value="Upload More Files" onClick={() => this.uploadMore()} />}
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Upload;